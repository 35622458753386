<template>
  <div class="homePage">
    <HomeHeader :companyOption="companyOption" @changeCompany="changeCompany"></HomeHeader>
    <div class="contentArea">
      <!-- <div class="leftLayout">
        <div class="noticeList">
          <p class="title">待办通知</p>
          <div class="cells" :style="'height:'+noticeHeight+'px;'">

            <div class="cell" v-for="(item,index) in messageList" :key="index" @click="messageJump(item)">
              <div class="left">
                <img :src="item.iconUrl?item.iconUrl:defaultPng" alt="">
                <span>{{item.serviceName}}</span>
              </div>
              <span class="notice" v-if="item.msgValue">{{item.msgValue}}</span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="leftLayout">
        <div class="topApp" ref="topApp">
          <div class="btn-left" @click="preClick()" v-if="productTotalRows > 1 && currentPage > 1">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="btn-right" @click="nextClick()" v-if="productTotalRows > 1 && currentPage !== productTotalRows">
            <i class="el-icon-arrow-right"></i>
          </div>
          <p class="title">应用中心</p>
          <div class="appBox">
            <div
              class="oneApp"
              v-for="(item, index) in productsMenu.slice((currentPage - 1) * 6, currentPage * 6)"
              :key="index"
              @click="platformJump(item)"
            >
              <img :src="item.iconUrl ? item.iconUrl : defaultPng" alt="" />
              <div>
                <p class="p1">{{ item.productName }}</p>
                <p class="p2">{{ item.productDesc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightLayout">
        <div class="common-tab-box">
          <div class="common-tab-left">
            <div
              :class="tabIndex == index ? 'common-tab-list common-tab-active' : 'common-tab-list'"
              v-for="(item, index) in tabList"
              :key="index"
              @click="tabClick(item, index, 'page')"
            >
              {{ item.tabName }}({{ index == 0 ? dealTotalRows : messageTotalRows }})
            </div>
          </div>
          <div class="common-tab-right" @click="drawer = true">
            <!-- <span v-if="showAllRead" @click="messageAllRead">
              <img src="../../assets/all-read.png" alt="">
              全部已读 </span>
            <span @click="drawer = true"> 更多</span> -->
            更多
            <!-- <el-popover popper-class="popper-class-home" placement="right" width="450" ref="popover" trigger="click">
              <span slot="reference">更多</span>
              <div class="hover-title">
                <span>通知中心</span>
                <i class="el-icon-close" @click="hidePopover()"></i>
              </div>
              <div class="common-tab-box">
                <div class="common-tab-left">
                  <div
                    :class="tabHoverIndex == index ? 'common-tab-list common-tab-active' : 'common-tab-list'"
                    v-for="(item, index) in tabList"
                    :key="index"
                    @click="tabClick(item, index, 'hover')"
                  >
                    {{ item.tabName }}({{ index == 0 ? dealTotalRows : messageTotalRows }})
                  </div>
                </div>
              </div>
              <div class="notice-hover-box">
                <div
                  class="notice-list"
                  @click="messageJumpHover(item)"
                  v-for="(item, index) in messageAllList"
                  :key="index"
                >
                  <div class="notice-list-left">
                    <span :style="item.isFinish !== 'Y' ? 'background:#fb2323;' : '#fff'"></span
                    ><span>{{ item.content || '-' }}</span>
                  </div>
                  <div class="notice-list-center">{{ item.content || '-' }}</div>
                  <div class="notice-list-right">{{ item.createTime | getTime }}</div>
                </div>
              </div>
              <p class="no-data" v-show="messageAllList.length == 0">暂无数据</p>
              <el-pagination
                class="pt pb back-color-fff t-center"
                background
                @current-change="handleCurrentChange"
                :current-page="msgPage.pageIndex"
                :page-size="msgPage.pageSize"
                layout="total,  prev, pager, next"
                :total="msgPage.totalRows"
                small
              >
              </el-pagination>
            </el-popover> -->
          </div>
        </div>
        <div
          class="notice-list"
          @click="messageJump(item, item.jumpUrl)"
          v-show="tabIndex == 0"
          v-for="(item, index) in dealList"
          :key="index"
        >
          <div class="notice-list-left">
            <span :style="item.isFinish !== 'Y' ? 'background:#fb2323;' : '#fff'"></span>{{ item.content || '-' }}
          </div>
          <div class="notice-list-right">{{ item.createTime | getTime }}</div>
        </div>
        <p class="no-data" v-show="tabIndex == 0 && dealList.length == 0">暂无数据</p>
        <div
          class="notice-list"
          style="cursor: auto;"
          @click="messageRemark(item, item.jumpUrl)"
          v-show="tabIndex == 1"
          v-for="(item, index) in messageList"
          :key="index"
        >
          <div class="notice-list-left">
            <span :style="item.isFinish !== 'Y' ? 'background:#fb2323;' : '#fff'"></span>{{ item.content || '-' }}
          </div>
          <div class="notice-list-right">{{ item.createTime | getTime }}</div>
        </div>
        <p class="no-data" v-show="tabIndex == 1 && messageList.length == 0">暂无数据</p>
      </div>
    </div>
    <div class="reportLayout">
      <div class="common-tab-box" v-if="reportList && reportList.length > 1">
        <div class="common-tab-left">
          <div
            :class="reportTabIndex == index ? 'common-tab-list common-tab-active' : 'common-tab-list'"
            v-for="(item, index) in reportList"
            :key="index"
            @click="reportTabClick(item, index)"
          >
            {{ item.menuName }}
          </div>
        </div>
      </div>
      <div class="report-content" v-if="reportPath">
        <iframe :src="reportPath" :style="{ height: BiHeight + 'px' }" class="iframe-sty" id="iframe"></iframe>
      </div>
    </div>
    <el-drawer title="通知中心" :visible.sync="drawer" direction="rtl"  :with-header="false" :modal="false" custom-class="popper-class-home" width="450px">
      <div class="hover-title">
        <span>通知中心</span>
        <i class="el-icon-close" @click="drawer = false"></i>
      </div>
      <div class="common-tab-box">
        <div class="common-tab-left">
          <div
            :class="tabHoverIndex == index ? 'common-tab-list common-tab-active' : 'common-tab-list'"
            v-for="(item, index) in tabList"
            :key="index"
            @click="tabClick(item, index, 'hover')"
          >
            {{ item.tabName }}({{ index == 0 ? dealTotalRows : messageTotalRows }})
          </div>
        </div>
      </div>
      <div class="notice-hover-box t-left">
        <div class="notice-list" @click="messageJumpHover(item)" v-for="(item, index) in messageAllList" :key="index">
          <div class="notice-list-left">
            <span :style="item.isFinish !== 'Y' ? 'background:#fb2323;' : '#fff'"></span
            ><span>{{ item.title || '-' }}</span>
          </div>
          <div class="common-tab-right">
            <span v-if="showAllRead" @click="messageAllRead">
            <img src="../../assets/all-read.png" alt="">
            全部已读 </span>
          </div>
          <div class="notice-list-center">{{ item.content || '-' }}</div>
          <div class="notice-list-right">{{ item.createTime | getTime }}</div>
        </div>
      </div>
      <p class="no-data" v-show="messageAllList.length == 0">暂无数据</p>
      <el-pagination
        class="pt pb back-color-fff t-center"
        background
        :current-page="msgPage.pageIndex"
        :page-size="msgPage.pageSize"
        layout="total,  prev, pager, next"
        :total="msgPage.totalRows"
        small
      >
      </el-pagination>
    </el-drawer>
  </div>
</template>
<script>
import newEcharts from 'echarts';
import HomeHeader from '@/components/homeHeader.vue';
import toOther from './mixins/toOtherReport';
import {websocketUrl} from '@/api/apiRoute'
import { JSEncrypt } from 'jsencrypt';
export default {
  name: 'marketingDigitalPlatform',
  components: {
    HomeHeader,
  },
  data() {
    return {
      defaultPng: require('../../assets/personalPage/icon3.png'),
      currentTenantGuid: null,
      currentStaffGuid: null,
      companyOption: [],
      organisationName: '--',
      positionName: '--',
      messageList: [],
      dealList: [],
      productsMenu: [],
      dimen: 'order', //order=按报货 flow=按流向
      isSaleMan: 1, //业务员和其他为1，省总为0
      echartHeight: 0,
      noticeHeight: 0,
      tabIndex: 0,
      tabList: [{ tabName: '待办' }, { tabName: '消息' }],
      messageType: '1', //消息类型 1待办 2消息
      dealTotalRows: 0,
      messageTotalRows: 0,
      reportList: [],
      reportTabIndex: 0,
      reportPath: null,
      currentPage: 1,
      productTotalRows: 0,
      BiHeight: 1000,
      //hover消息
      tabHoverIndex: 0,
      messageHoverType: '1', //消息类型 1待办 2消息
      msgPage: {
        pageIndex: 1,
        pageSize: 50,
        totalPage: 0,
        totalRows: 0,
        messageType: '1',
        tenantGuid: null,
        staffGuid: null,
        isFinish: 'N',
      },
      messageAllList: [],
      drawer: false,
      showAllRead:false
    };
  },
  mixins: [toOther],
  created() {
    document.title = '个人中心';
    localStorage.removeItem('loginType');
    if (localStorage.menuList) {
      let menuList = JSON.parse(localStorage.menuList);
      if (menuList && menuList.length > 0) {
        menuList.forEach(i => {
          if (i.menuName == '主页') {
            this.reportList = i.children || [];
          }
        });
      }
    }
    this.getTenenants();
    this.createServerConnect();

  },
  mounted() {
    this.noticeHeight = window.innerHeight - 170;
  },
  methods: {
    createServerConnect(){
      const that =this;
      const staffGuid = JSON.parse(localStorage.userInfo).staffGuid;
      const ws = new WebSocket(`wss://${websocketUrl}/websocket/${staffGuid}`);
      ws.onopen = function() {
        console.log('Connected to server.');
      };
      ws.onmessage = function(res) {
        if (res.data == '\"连接成功\"') return;
        that.getTenenants();
      };
    },
    receiveMessage(event) {
      this.BiHeight = event.data.data ? event.data.data.height + 120 : 1000;
      console.log('BI高度接收', event);
      // ...
    },
    tabClick(item, index, type) {
      if (type == 'page') {
        this.tabIndex = index;
        this.messageType = index == 0 ? '1' : '2';
        if (index == 0) {
          this.showAllRead=false
          this.getDealtList();
        } else {
          this.showAllRead=true
          this.getMessageList();
        }
      } else {
        this.tabHoverIndex = index;
        this.msgPage.messageType = index == 0 ? '1' : '2';
        this.getAllMessageList();
      }
    },
    reportTabClick(item, index) {
      this.reportTabIndex = index;
      let loginInfo = JSON.stringify({
        username: 'ExternalAccount',
        pwd: 'd70413d70ad4af480fa4c6952ab8edb3',//正式
        // pwd: '21232f297a57a5a743894a0e4a801fc3',//测试
        timestamp: new Date().getTime()
      })
      // let path = 'http://localhost:8093/Look?pageGuid=8acd011d7ae24b5099f88f669b331a2b';
      this.reportPath = `${item.path}&loginInfo=${encodeURI(this.encrypt(loginInfo))}&tenantGuid=${this.$route.query.currentTenantGuid}&staffGuid=${JSON.parse(localStorage.userInfo).staffGuid}&hasScrollbar=false`;
      console.log('first', this.reportPath);
      window.addEventListener('message', this.receiveMessage);
    },
    preClick() {
      this.currentPage = this.currentPage - 1;
    },
    nextClick() {
      this.currentPage = this.currentPage + 1;
    },
    //获取消息列表
    getMessageList() {
      let query = {
        messageType: 2,
        pageIndex: 1,
        pageSize: 4,
        tenantGuid: this.currentTenantGuid,
        staffGuid: this.currentStaffGuid,
        isFinish: 'N',
        appSideName:'企业端',
      };
      this.api.postJson(`/${serverConfig.msConfigureService}/message/data/get-message-list`, query).then(res => {
        if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
          this.messageList = res.data.data.records || [];
          this.messageTotalRows = res.data.data.totalRows;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取待办列表
    getDealtList() {
      let query = {
        messageType: 1,
        pageIndex: 1,
        pageSize: 4,
        tenantGuid: this.currentTenantGuid,
        staffGuid: this.currentStaffGuid,
        isFinish: 'N',
      };
      this.api.postCan(`/${serverConfig.msConfigureService}/message/data/get-message-list`, query).then(res => {
        if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
          this.dealList = res.data.data.records || [];
          this.dealTotalRows = res.data.data.totalRows;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //hover时的效果
    messageJumpHover(item) {
      if (item.messageType == '1') {
        this.messageJump(item, item.jumpUrl);
      } else {
        this.messageRemark(item, item.jumpUrl);
      }
    },
    //隐藏hover消息
    hidePopover() {
      this.$refs.popover.doClose();
    },
    //hover获取所有消息列表
    getAllMessageList() {
      this.msgPage.tenantGuid = this.currentTenantGuid;
      this.msgPage.staffGuid = this.currentStaffGuid;
      this.api.postJson(`/${serverConfig.msConfigureService}/message/data/get-message-list`, this.msgPage).then(res => {
        if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
          this.messageAllList = res.data.data.records || [];
          this.msgPage.totalPage = res.data.data.totalPages;
          this.msgPage.totalRows = res.data.data.totalRows;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //消息标记
    messageRemark(item) {
      this.api.postJson(`/${serverConfig.msConfigureService}/message/data/update-state`, [item.guid]).then(res => {
        if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
          this.$message.success('标记成功！');
          // this.getMessageList();
          this.messageJump(item);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //消息全部已读
    messageAllRead() {
      this.$confirm('确定全部标记为已读吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        this.api.put(`/${serverConfig.msConfigureService}/message/update-all-finish?staffGuid=${this.currentStaffGuid}`).then((res) => {
          if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
            this.$message.success('操作成功！');
            this.getMessageList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      })
    },
    //切换公司
    changeCompany(guid) {
      this.getMenuList(guid);
    },
    getMenuList(tenantGuid) {
      let self = this;
      self.api
        .get(
          '/ms-common-admin-platform-auth-service/menu/data/get-menu-by-currentuser?' +
            `appType=01&tenantGuid=` +
            tenantGuid +
            `&appSideIdentifier=QYD`,
        )
        .then(res => {
          if (res.data.code == '00000') {
            self.menuList = res.data.data;
            if (res.data.data[0].menuName == '主页') {
              this.getCurrentstaff(tenantGuid, res.data.data[0].path);
              // window.location = location.origin + res.data.data[0].path + "?username=" + self.$route.query
              //   .username +
              //   '&password=' + self.$route.query.password + '&currentTenantGuid=' + tenantGuid;
            }
          } else {
            self.$message.error(res.data.msg);
            self.loadingInstance.close();
          }
        });
    },
    getCurrentstaff(tenantGuid, path) {
      let self = this;
      self.api.get('/ms-personnel-service/staff/data/get-currentstaff?' + `tenantGuid=` + tenantGuid).then(res => {
        if (res.data.code == '00000') {
          self.$store.commit('$_setUserId', res.data.data.userGuid);
          localStorage.setItem('userInfo', JSON.stringify(res.data.data));
          window.location.replace(
            location.origin +
              path +
              '?username=' +
              self.$route.query.username +
              '&password=' +
              self.$route.query.password +
              '&currentTenantGuid=' +
              tenantGuid,
          );
        } else {
          self.$message.error(res.data.msg);
          self.loadingInstance.close();
        }
      });
    },
    //获取当前用户公司信息
    getTenenants() {
      var userGuid = localStorage.userId;
      this.api.get('/ms-personnel-service/staff-home/tenants?userGuid=' + userGuid).then(res => {
        if (res.data.code == '00000') {
          this.companyOption = res.data.data.records;
          this.currentTenantGuid = this.$route.query.currentTenantGuid;
          this.companyOption.forEach(item => {
            if (item.tenantGuid == this.currentTenantGuid) {
              this.currentStaffGuid = item.staffGuid;
            }
          });
          if (this.reportList && this.reportList.length > 0) {
            let loginInfo = JSON.stringify({
                username: 'ExternalAccount',
                pwd: 'd70413d70ad4af480fa4c6952ab8edb3',//正式
                // pwd: '21232f297a57a5a743894a0e4a801fc3',//测试
                timestamp: new Date().getTime()
              })
            // let path = 'http://43.254.3.69:3061/Look?pageGuid=3b925cdebec745dc869a11d6c394d979';
            this.reportPath = `${this.reportList[0].path}&loginInfo=${encodeURI(this.encrypt(loginInfo))}&tenantGuid=${this.$route.query.currentTenantGuid}&staffGuid=${JSON.parse(localStorage.userInfo).staffGuid}&hasScrollbar=false`;
          }
          window.addEventListener('message', this.receiveMessage);
          console.log(this.reportPath);
          this.getDealtList();
          this.getMessageList();
          this.getAllMessageList();
          this.getOrgAndProduct();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取当前公司下的菜单
    getOrgAndProduct() {
      this.api
        .get(
          '/ms-personnel-service/staff-home/org-and-product?staffGuid=' +
            this.currentStaffGuid +
            '&tenantGuid=' +
            this.currentTenantGuid,
        )
        .then(res => {
          if (res.data.code == '00000') {
            this.organisationName = res.data.data.organisationName;
            this.positionName = res.data.data.positionName;
            this.productsMenu = res.data.data.products;
            this.productTotalRows = Math.ceil(this.productsMenu.length / 6);
            console.log('first', this.productTotalRows);
            this.productsMenu.forEach(item => {
              if (item.icon) {
                let obj = JSON.parse(item.icon);
                this.$set(item, 'iconUrl', obj.path);
              }
            });
            //渲染应用产品后，渲染图表
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    msgMore() {
      window.open(
        location.origin +
          '/messageManagementList?staffGuid=' +
          this.currentStaffGuid +
          '&tenantGuid=' +
          this.currentTenantGuid +
          '&username=' +
          this.$route.query.username +
          '&password=' +
          this.$route.query.password +
          '&logoTitle=' +
          this.$route.query.logoTitle,
      );
    },
    //切换图标tab
    changeTab(e) {},
    encrypt(txt) {
      let encryptor = new JSEncrypt()
      let publicKey = '-----BEGIN PUBLIC KEY-----MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgFiv3Ko6rOBvWDK96tIExpdyuuQAGgZo0YmQxpT10hD3qu/MnNKlIJgo4+NwUHcwpleKE2DBOxgvCeMtUoP4FDWt8q18X+4+7t8p0D/57NhA0liNKQ/Ise6b5i293ht1XPen3XhR5xIcDNxauQ5vKqdwwzhoonsbJDtbowoinLQbAgMBAAE=-----END PUBLIC KEY-----';
      encryptor.setPublicKey(publicKey) // 设置公钥
      return encryptor.encrypt(txt); // 对数据进行加密
    },
  },
};
</script>

<style scoped>
.topApp p,
.noticeList p {
  padding: 0;
  margin: 0;
}
.homePage {
  position: relative;
  background: #f6f7fa;
  min-height: 100vh;
  width: 100%;
  padding: 0.8rem 1rem 1rem 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.logoArea {
  display: flex;
  align-items: flex-end;
  padding-bottom: 1.5rem;
}

.logoArea img {
  height: 2.125rem;
  margin-right: 1.875rem;
}

.logoArea span {
  font-size: 1.25rem;
  color: #323233;
}

.contentArea {
  display: flex;
  /* flex: 1; */
  height: 100%;
  width: 100%;
  /* background: #fff; */
}

/* 左侧 */
.leftLayout {
  width: 70%;
  flex-shrink: 0;
  /* padding-left: 1rem; */
  margin-right: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.topApp {
  position: relative;
  min-height: 270px;
  background: #fff;
  padding: 1.25rem 1.5rem 0.25rem 1.5rem;
  box-sizing: border-box;
}
.topApp .title {
  font-size: 1.1rem;
  color: #323233;
  /* border-bottom: 1px solid #DFE1E6; */
  /* margin-bottom: 1rem; */
  padding-left: 10px;
  padding-bottom: 1rem;
  text-align: left;
  font-weight: 500;
}

.topApp .appBox {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.topApp .oneApp {
  width: 32%;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: left;
  /* background: #F6F7FA; */
  border: 1px solid #d9d9d9;
  margin-right: 2%;
  margin-bottom: 1rem;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;
}
.topApp .oneApp:nth-child(3n) {
  margin-right: 0;
}
.topApp .oneApp:hover {
  background: #fafafa;
  transition: all 0.5s;
  box-shadow: 0px 0 0.625rem 3px rgba(0, 0, 0, 0.1);
}

.topApp .oneApp img {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}

.topApp .oneApp .p1 {
  font-size: 1.1rem;
  margin-bottom: 4px;
  font-weight: 400;
}

.topApp .oneApp .p2 {
  font-size: 0.8rem;
  color: #6e6e6e;
}
.topApp i {
  font-size: 18px;
  color: #fff;
}
.btn-left {
  position: absolute;
  top: 120px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 40px;
  opacity: 0.25;
  background: #000000;
  border-radius: 2px;
  cursor: pointer;
}
.btn-right {
  position: absolute;
  top: 120px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 40px;
  opacity: 0.25;
  background: #000000;
  border-radius: 2px;
  cursor: pointer;
}
/* 右侧 */
.rightLayout {
  width: calc(30% - 4px);
  /* height: 100%; */
  flex: 1;
  background: #fff;
}
.rightLayout .notice-list,
.notice-hover-box .notice-list {
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
  line-height: 25px;
  box-sizing: border-box;
}
.rightLayout .notice-list:hover,
.notice-hover-box .notice-list:hover {
  background: #fafafa;
  cursor: pointer;
}
.rightLayout .notice-list-left,
.notice-hover-box .notice-list-left {
  display: flex;
  flex: 1;
  text-align: left;
}
.rightLayout .notice-list-left span:first-child,
.notice-hover-box .notice-list-left span:first-child {
  float: left;
  flex-shrink: 0;
  display: block !important;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 10px;
}
.rightLayout .notice-list-left span:last-child {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.notice-hover-box .notice-list-left span:last-child {
  display: -webkit-box !important;
}
.notice-list-right,
.notice-hover-box .notice-list-right {
  font-size: 12px;
  flex-shrink: 0;
  padding-left: 10px;
}
.notice-hover-box .notice-list {
  display: block;
  border-bottom: 1px solid #d9d9d9;
}
.notice-hover-box .notice-list-center {
  font-size: 12px;
  color: #666;
  padding-left: 13px;
}
.notice-hover-box .notice-list-right {
  color: #999999;
  padding-left: 13px;
}
.hover-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 10px;
}
.hover-title span {
  display: block;
  font-size: 16px;
}
.hover-title i {
  font-size: 20px;
  cursor: pointer;
}
/* 页面tab */
.common-tab-box {
  display: flex;
  justify-content: space-between;
  height: 32px;
  line-height: 32px;
  color: #666;
  margin-top: 4px;
  /* margin: 16px 16px 4px 16px; */
  border-bottom: 1px solid #e5e5e5;
  outline: none;
  background: #fff;
}
.common-tab-left {
  display: flex;
}
.common-tab-list {
  padding: 0 20px;
  /* margin: 0 5px; */
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
}
.common-tab-active {
  color: #4fa1a4;
  border-bottom: 2px solid #4fa1a4;
}
.common-tab-right {
  font-size: 12px;
  color: #666666;
  cursor: pointer;
  margin-right: 5px;
}
.common-tab-right img{
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom
  }
.no-data {
  color: #666;
  font-size: 12px;
  margin: 20px;
}
.iframe-sty {
  width: 100%;
  min-height: 100vh;
  border: none;
  /* background:grey; */
}
.report-content {
  margin-top: 4px;
}
.notice-hover-box {
  max-height: calc(100vh - 140px);
  overflow: auto;
}
.t-center {
  text-align: center;
}
</style>
<style>
::-webkit-scrollbar {
  width: 10px !important;
}
.popper-class-home {
  position: absolute;
  width: 450px!important;
  height: 100vh;
  padding: 12px 0 !important;
}
/* .scroller::-webkit-scrollbar-track {
  background-color:transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
.scroller::-webkit-scrollbar-thumb {
  background-color: rgb(147,147,153,0.5);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
} */
</style>
